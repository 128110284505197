(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

(function () {
  function r(e, n, t) {
    function o(i, f) {
      if (!n[i]) {
        if (!e[i]) {
          var c = "function" == typeof require && require;
          if (!f && c) return c(i, !0);
          if (u) return u(i, !0);
          var a = new Error("Cannot find module '" + i + "'");
          throw a.code = "MODULE_NOT_FOUND", a;
        }

        var p = n[i] = {
          exports: {}
        };
        e[i][0].call(p.exports, function (r) {
          var n = e[i][1][r];
          return o(n || r);
        }, p, p.exports, r, e, n, t);
      }

      return n[i].exports;
    }

    for (var u = "function" == typeof require && require, i = 0; i < t.length; i++) {
      o(t[i]);
    }

    return o;
  }

  return r;
})()({
  1: [function (require, module, exports) {
    "use strict";

    $(document).ready(function () {
      console.log('...');
      $('a[data-fancybox]').fancybox({
        animationEffect: "fade"
      });
      var swiper1 = new Swiper(".technical-service__cards .swiper", {
        slidesPerView: 2,
        spaceBetween: 30,
        speed: 700,
        breakpoints: {
          0: {
            slidesPerView: 1,
            spaceBetween: 15
          },
          576: {
            slidesPerView: 2,
            spaceBetween: 15
          },
          767: {
            slidesPerView: 3,
            spaceBetween: 15
          },
          991: {
            slidesPerView: 4,
            spaceBetween: 15
          }
        }
      });

      if ($('#map').length > 0) {
        mapboxgl.accessToken = 'pk.eyJ1IjoiYWdlbmN5bW9vbiIsImEiOiJja2xyM211MXUxMXJrMnVud2RtM29ka2p2In0.jCNNfWIhd_LBhxrMe46imw';
        var geojson = {
          type: 'FeatureCollection',
          features: [{
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [29.235554, 40.998172]
            },
            properties: {
              title: 'Mapbox',
              description: 'center'
            }
          }]
        };
        var width = $(window).width(); //console.log(width);

        var map = new mapboxgl.Map({
          container: 'map',
          style: 'mapbox://styles/agencymoon/cl0bftn8u008n14mg75axzb3h',
          center: [29.235554, 40.99817],
          // starting position [lng, lat]
          zoom: 13 //zoom: width>=720 ? 13 : 11

        });
        geojson.features.forEach(function (marker) {
          // create a HTML element for each feature
          var el = document.createElement('div');
          el.className = 'marker_' + marker.properties.description; // make a marker for each feature and add to the map

          new mapboxgl.Marker(el).setLngLat(marker.geometry.coordinates).addTo(map);
        });
      }
      /*var swiper1 = new Swiper(".products-swiper", {
             slidesPerView: 3,
             spaceBetween: 27,
      	speed:800,
             navigation: {
      		nextEl: ".product-swipe-button-next",
      		prevEl: ".product-swipe-button-prev",
      	},
      	breakpoints: {
      		'0': {
      			slidesPerView: 1,
      			spaceBetween: 0,
      		  },
      		'768': {
      		  slidesPerView: 2,
      		  spaceBetween: 20,
      		},
      		'991': {
      		  slidesPerView: 3,
      		  spaceBetween: 27,
      		},
      	}
         });*/
      //cokie

      /*var _cookiePolicy = Cookies.get("cookiePolicy");
         if ($(".cookie-policy").length > 0 && _cookiePolicy != "true"){
             $('.cookie-policy').addClass('active');
         }
         $('.cookie-policy .cookie-close').click(function(e){
             e.preventDefault();
             $(this).parents('.cookie-policy').removeClass('active');
             Cookies.set("cookiePolicy", "true", {expires: 999});
         });*/
      //form


      $('.js-letters_only').keyup(function () {
        this.value = this.value.replace(/[^a-zA-ZıİşŞÜüğĞÇçöÖ ]/g, '');
      });
      $.validator.addMethod("validate_email", function (value, element) {
        if (/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(value)) {
          return true;
        } else {
          return false;
        }
      });
      $.validator.addMethod("lettersonly", function (value, element) {
        return this.optional(element) || /^[a-zA-Z ıİşŞÜüğĞÇçöÖ]+$/i.test(value);
      });
      $('.js_phone_mask').mask('500 000 00 00');
      $("#contactForm").validate({
        ignore: [],
        errorClass: "error",
        errorPlacement: function errorPlacement(error, element) {
          var elementName = element[0].className; //console.log(elementName);

          if (elementName == 'parent-error error') {
            $(element).parent(".form-item").addClass('error-validation');
            error.insertAfter($(element)); //error-msg add.
          } else {
            error.insertAfter($(element)); //error-msg add.
          }
        },
        rules: {
          name: {
            required: true,
            lettersonly: true,
            minlength: 3
          },
          email: {
            required: true,
            validate_email: true
          },
          phone: {
            required: true,
            minlength: 13
          },
          message: {
            required: true,
            minlength: 4
          }
        },
        submitHandler: function submitHandler(form) {
          formSend(form);
        },
        invalidHandler: function invalidHandler(form, validator) {}
      });
      $("#technicalForm").validate({
        ignore: [],
        errorClass: "error",
        errorPlacement: function errorPlacement(error, element) {
          var elementName = element[0].className; //console.log(elementName);

          if (elementName == 'parent-error error') {
            $(element).parent(".form-item").addClass('error-validation');
            error.insertAfter($(element)); //error-msg add.
          } else {
            error.insertAfter($(element)); //error-msg add.
          }
        },
        rules: {
          company_name: {
            required: true,
            lettersonly: true,
            minlength: 3
          },
          authorized_name: {
            required: true,
            lettersonly: true,
            minlength: 3
          },
          reason_for_request: {
            required: true
          },
          authorized_phone: {
            required: true,
            minlength: 13
          },
          device_information: {
            required: true,
            minlength: 3
          },
          authorized_email: {
            required: true,
            validate_email: true
          },
          message: {
            required: true,
            minlength: 4
          }
        },
        submitHandler: function submitHandler(form) {
          formSend(form);
        },
        invalidHandler: function invalidHandler(form, validator) {}
      });
    });

    function formSend(form) {
      var action = $(form).attr("action"),
          method = $(form).attr("method"),
          name = $(form).attr('name'),
          veri = $(form).serialize(),
          validate = $(form);
      $.ajax({
        type: method,
        url: action,
        data: veri,
        cache: false,
        success: function success(response) {
          console.log(response);
          response = JSON.parse(response); //response = 1;

          if (response.status == 0) {
            Swal.fire({
              icon: 'success',
              title: 'Başarılı',
              text: response.responseText,
              timer: 1500
            });
          } else if (response.status == 1) {
            Swal.fire({
              icon: 'warning',
              title: 'Uyarı',
              text: response.responseText,
              timer: 1500
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Hata',
              text: response.responseText,
              timer: 1500
            });
          }
          /*setTimeout(function () {
            window.location.reload(true);
          }, 1500);*/

        }
      });
    }
  }, {}]
}, {}, [1]);

},{}]},{},[1]);
